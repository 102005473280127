<template>
    <div style="height: 100vh;overflow-y: hidden">

      <div v-if="!selectedDate" class="display-container">
          <lbrx-kiosk-header :logo="themeTemplate.header.logo" :hasBorderRadius="themeTemplate.header.borderRadius"  v-model="validating" :primaryColor="themeTemplate.primaryColor" :secondaryColor="themeTemplate.secondaryColor" :text-color="themeTemplate.textColor" :header-background="themeTemplate.header.headerBackground"></lbrx-kiosk-header>
          <div v-if="sigleDepartment" class="mainContainer">
<!--            <lbrx-booking-first-theme v-if="theme === 'firstTheme'"></lbrx-booking-first-theme>-->
            <lbrx-booking-second-theme @validate="getServices" :themeTemplate="themeTemplate"></lbrx-booking-second-theme>
          </div>
          <div v-else class="mainContainer">
              <lbrx-booking-multiple-dep @validate="validateData"  :themeTemplate="themeTemplate"></lbrx-booking-multiple-dep>
          </div>
          <div class="footer-container">
            <the-footer :color="themeTemplate.textColor" v-model="onLine" :background="themeTemplate.footer.footerBackground"></the-footer>
          </div>
      </div>
        <div style="height: 100%" v-else-if="showServices && selectedDate">
          <lbrx-kiosk-fifth-theme @cancel="selectedDate = null" v-model="validating" :booking="true" :popup="false" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" :services="services" :themeTemplate="themeTemplate"></lbrx-kiosk-fifth-theme>
        </div>
        <div style="height: 100%" v-else>
            <lbrx-print-ticket-box @cancel_idle="cancelIdle" @start_idle="startIdle" @sending="validating = !validating" @bookingDone="bookingDone()" :directPrint="directPrint" @cancel="showServices = true" :noAnonym="true" :services="services" :selectedDate="selectedDate" :department="department" :agent="agent" v-model="validating" :theme-template="themeTemplate"></lbrx-print-ticket-box>
        </div>
        <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable" :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor" :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>
        <lbrx-kiosk-services-popup :theme-template="themeTemplate" :service="clickedService" v-if="showServicesPopup && clickedService" @close="validateClickedService" v-model="showServicesPopup"></lbrx-kiosk-services-popup>
        <lbrx-quantity-picker-popup v-if="showQuantityPicker" @submit-quantity="setQuantity" @close="showQuantityPicker = false, setQuantityFor = null" v-model="showQuantityPicker" :max="20" :min="1" :theme-template="themeTemplate"></lbrx-quantity-picker-popup>
    </div>
</template>

<script>
import LbrxKioskHeader from "@/components/LbrxKioskHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxBookingSecondTheme from "@/components/LbrxBookingSecondTheme.vue";
import LbrxKioskFifthTheme from "@/components/LbrxKioskFifthTheme.vue";
import {kioskService} from "@/_services";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
import LbrxPrintTicketBox from "@/components/LbrxPrintTicketsBox.vue";
import LbrxKioskServicesPopup from "@/components/LbrxKioskServicesPopup.vue";
import LbrxBookingMultipleDep from "@/components/LbrxBookingMultiipleDep.vue";
import LbrxQuantityPickerPopup from "@/components/LbrxQuantityPickerPopup.vue";
import router from "@/router";

export default {
  name: 'KioskBooking',
  inject: ['echo', 'display'],
  components: {
      LbrxQuantityPickerPopup,
      LbrxBookingMultipleDep,
      LbrxKioskServicesPopup,
      LbrxPrintTicketBox,
      LbrxKioskPopup,
      LbrxKioskFifthTheme,
      LbrxBookingSecondTheme,
      TheFooter,
      LbrxKioskHeader
  },
  data() {
    return {
      allowIdle: true,
      showQuantityPicker:false,
      setQuantityFor:null,
      clickedService: null,
      showServicesPopup:false,
      validating: false,
      locale: 'fr',
      config: {},
      onLine: navigator.onLine,
      showBackOnline: false,
      services: [],
      selectedDate: null,
      boxHeight:'40%',
      boxContent: '',
      boxIcon: 'loader',
      boxTextColor: '',
      isClosable: false,
      boxBackGround: '#FFFFFF',
      boxIconColor: '',
      showPopup: false,
      showServices:false,
      sigleDepartment:false,
      department:null,
      agent:null,
      themeTemplate:null,
      directPrint: false,
      idleTimeout:null,
      numberOfChecked:0,
      nextCountdown: null
    }
  },
  created() {
    document.addEventListener('click', () => {
      this.setIdleTimeout();
    });
      this.themeTemplate = JSON.parse(window.localStorage.getItem('theme'));
      this.setIdleTimeout();
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      if(JSON.parse(window.localStorage.getItem("business")).departments.length === 1)
          this.sigleDepartment = JSON.parse(window.localStorage.getItem("business")).departments[0].dep_members.length < 2

  },
    methods: {
      cancelIdle() {
        this.allowIdle = false;
        clearTimeout(this.idleTimeout);
      },
      startIdle() {
        this.allowIdle = true;
        this.setIdleTimeout();
      },
        setIdleTimeout() {
          clearTimeout(this.idleTimeout);
            if(this.themeTemplate.IdleTimeout && this.allowIdle) {
                this.idleTimeout = setTimeout(() => {
                    if (router.currentRoute.value.name !== 'waitingQueue') {
                        router.push({name: 'waitingQueue'});
                    }
                }, this.themeTemplate.IdleTimeout)
            }
        },
        openQuantity(service) {
            if(!JSON.parse(JSON.parse(window.localStorage.getItem("kiosk")).config).allow_quantity_services)
                return;
            if(service.child)
                if(service.child.length)
                    return;
            this.setQuantityFor = this.services[this.services.indexOf(service)];
            this.showQuantityPicker = true;
            this.services[this.services.indexOf(service)].checked = true;
        },
        updateOnlineStatus(e) {
            const {type} = e
            this.onLine = type === 'online'
        },
      validateClickedService() {
          this.clickedService.checked = false;
          this.clickedService.child.forEach((c)=>{
              if(c.checked) {
                  this.clickedService.checked = true;
              }
          })
          this.disableOtherQueues(this.clickedService);
          this.showServicesPopup = false;
      },
      bookingDone() {
            this.showServices = true;
            router.push({name:'waitingQueue'});
      },
      validateServices() {
          this.validating = true;
          let serviceChecked = false;
          this.services.forEach((service) => {
              if (service.checked) {
                  serviceChecked = true;
              }
          });
          if (!serviceChecked) {
              //fire error;
              this.validating = false;
              this.showErrorPopup(this.$t('Kiosk.servicesError'));
              return;
          }
          this.showServices = false;
          this.validating = false;
      },
      showErrorPopup(errorText) {
          this.showPopup = false;
          this.boxIcon = 'error';
          this.isClosable = true;
          this.boxContent = errorText;
          this.boxTextColor = '#EB5757'
          this.boxBackGround = '#FFFFFF';
          this.boxIconColor = '#EB5757';
          this.boxHeight = "40%";
          this.showPopup = true;
      },
      setQuantity(qte) {
          this.setQuantityFor.qte = qte;
      },
      checkService(service) {
        clearTimeout(this.nextCountdown)
        clearTimeout(this.idleTimeout);
          if(service.disabled || this.validating)
              return;
          if(service.child && service.child.length > 0) {
              this.clickedService = service;
              this.showServicesPopup = true;
          } else {
              this.services[this.services.indexOf(service)].checked = !this.services[this.services.indexOf(service)].checked;
              if(!service.checked) {
                if(this.numberOfChecked > 0)
                  this.numberOfChecked--;
                service.qte = null;
              } else {
                this.numberOfChecked++;
              }
            if(this.numberOfChecked > 0) {
              this.startNextPageCountdown();
            }
              this.disableOtherQueues(service);
          }
      },
      startNextPageCountdown() {
        clearTimeout(this.idleTimeout);
        clearTimeout(this.nextCountdown);
        if(this.themeTemplate.onServiceSelect != null) {
          if(this.themeTemplate.onServiceSelect.status) {
            this.nextCountdown = setTimeout(()=> {
              this.validateServices();
              this.setIdleTimeout();
            }, this.themeTemplate.onPrintTicket.timeout);
          }
        }
      },
      disableOtherQueues(service) {
          if(service.checked) {
              if(!+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services) {
                  this.showServices = false;
              }
              this.services.forEach((serv)=>{
                  if (service.queue_id !== serv.queue_id || (!+JSON.parse(JSON.parse(window.localStorage.getItem("kiosk")).config).allow_multiple_services && serv.id !== service.id)) {
                      serv.checked = false;
                      serv.disabled = true;
                  }
              })
          } else {
              for(let i=0;i<this.services.length;i++) {
                  if(this.services[i].checked) {
                      return;
                  }
              }
              this.services.forEach((serv)=>{
                  serv.disabled = false;
              });
          }
      },
    validateData(data) {
      this.selectedDate = {};
      this.department = data.department;
      this.services = this.department.services;
      this.agent = data.agent;
      this.selectedDate.date = data.date.date;
      this.selectedDate.time = data.date.time;
      this.showServices = true;
    },
    getServices(selectedDate) {
        this.selectedDate = selectedDate;
        this.validating = true;
        this.showServices = true;
        if(window.localStorage.getItem("services")) {
            this.services = JSON.parse(window.localStorage.getItem("services"));
            this.validating = false;
        } else {
            this.validating = true;
            kioskService.loadServices().then(()=>{
                this.getServices();
            })
        }
    }
  },
  computed: {
      getBackground() {
          return this.themeTemplate.booking.background;
      }
  },
}
</script>

<style scoped>
.footer-container {
    width: 100% !important;
    height: auto;
}

.display-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
.mainContainer {
    background: v-bind(getBackground);
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;
}
</style>