import {ApiConfigs, authHeader} from '../_helpers';
import router from "@/router";

export const kioskService = {
    login,
    autoLogin,
    logout,
    loadServices,
    refresh,
    getQueueById,
    searchClient,
    createCustomer,
    joinQueue,
    ping,
    generateAuthCode,
    getAvailableSlots,
    createBooking,
    updateCustomer,
    getTerms,
    getPolicy,
    getBusinessTerms,
    getGlobalTerms,
    getBusinessPolicies,
    getGlobalPolicies,
    confirmAuthByCode
};

function login(key: any, secret: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Content-Language':'en'},
        body: JSON.stringify({key, secret})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.login}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            // login successful if there's a jwt token in the response
            if (kiosk.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('kiosk', JSON.stringify(kiosk));
            }
            return kiosk;
        });
}

function confirmAuthByCode(payload:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.confirmAuthByCode}`, requestOptions).then(handleResponse);
}

function autoLogin(key: string, token: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.autoLogin
        .replace(':key', key).replace(':token', token)}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            // login successful if there's a jwt token in the response
            if (kiosk.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('kiosk', JSON.stringify(kiosk));
            }
            return kiosk;
        });
}

function logout() {
    // remove user from local storage to log user out
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.logout}`, requestOptions)
        .then(handleResponse)
        .then(function () {
            localStorage.removeItem('token')
            localStorage.removeItem('kiosk')
            localStorage.removeItem('business')
            localStorage.removeItem('kiosk')
        }).then(function () {
            router.push('/login')
        }).catch(handleRejected);
}

async function loadServices() {
    let queues = JSON.parse(JSON.parse("" + localStorage.getItem('kiosk')).config).queues;
    queues = queues.split(',');
    const services: any[] = [];

    try {
        const queuesObject: any[] = [];
        const results = await Promise.all(queues.map((queue: any) => getQueueById(queue)));
        results.forEach((res) => {
            queuesObject.push(res);
            console.log(res)
            services.push(...res.services);
        });

        window.localStorage.setItem("queues", JSON.stringify(queuesObject));
        window.localStorage.setItem("services", JSON.stringify(services));
    } catch (err) {
        console.log(err);
    }
}
function refresh() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.refresh}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            localStorage.setItem('kiosk', JSON.stringify(kiosk.kiosk))
            localStorage.setItem('business', JSON.stringify(kiosk.business))
        }).catch(handleRejected);
}

function joinQueue(payload:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.push}`, requestOptions).then(handleResponse);
}
function getTerms(id:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.terms.replace(':id', id)}`, requestOptions).then(handleResponse);
}
function getBusinessTerms(id:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.businessTerms.replace(':id', id)}`, requestOptions).then(handleResponse);
}
function getGlobalTerms() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.businessTerms.replace(':id', '0')}`, requestOptions).then(handleResponse);
}
function getBusinessPolicies(id:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.businessPolicy.replace(':id', id)}`, requestOptions).then(handleResponse);
}
function getGlobalPolicies() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.businessPolicy.replace(':id', '0')}`, requestOptions).then(handleResponse);
}
function getPolicy(id:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.policy.replace(':id', id)}`, requestOptions).then(handleResponse);
}
function createBooking(payload:any) {
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.booking.create}`, requestOptions).then(handleResponse);
}

function createCustomer(payload:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.create_customer}`, requestOptions).then(handleResponse);
}

function searchClient(payload:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.search}`, requestOptions).then(handleResponse);
}

function getQueueById(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.get.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}
function getAvailableSlots(payload:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };
    return fetch(`${ApiConfigs.base_url_user + ApiConfigs.booking.getAvailableSlots}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function updateCustomer(id:number,payload:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.customers.update.replace(':id', String(id))}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function ping(payload: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {...authHeader()},
        body: payload
    }

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.ping}`, requestOptions)
        .then(handleResponse).catch(handleRejected);
}

function generateAuthCode() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosk.generateAuthCode}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function handleResponse(response: { text: () => Promise<any>; ok: any; status: number; statusText: any; }) {
    return response.text().then(text => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('kiosk');
                // auto logout if 401 response returned from api
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleRejected(exception: any) {
    console.log(exception);
}