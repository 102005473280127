<template>
  <div :class="{'right-class': $i18n.locale === 'ar'}" class="footer-container justify-content-between">
      <div class="footer-items">
          <div class="labelsContainer">
              <div @click="isTerms = true, isPrivacy = false,showTermsAndConditions = true" :style="{'border-right': $i18n.locale !== 'ar' ? '1px solid white'  : '', 'border-left': $i18n.locale === 'ar' ? '1px solid white'  : '' , 'padding-right': $i18n.locale !== 'ar' ? '20px'  : '', 'padding-left': $i18n.locale === 'ar' ? '20px'  : '' }" class="footerLabel terms">
                  {{ $t('Kiosk.footerFirst') }}
              </div>
              <div @click="isTerms = false, isPrivacy = true,showTermsAndConditions = true" class="footerLabel">
                  {{ $t('Kiosk.footerSecond') }}
              </div>
          </div>
      </div>
    <div class="footer-items main-copyright animate-flicker" style="margin: auto;height: 22px">
      {{modelValue? '':$t('internet_status.off')}}
    </div>
      <div class="poweredByContainer">
          <div class="poweredBy">{{ $t('Kiosk.footerThird') }}</div> <img :style="{'margin-left': $i18n.locale !== 'ar' ? '10px'  : '15px','margin-right': $i18n.locale === 'ar' ? '10px'  : ''}" class="footer-logo" src="../assets/images/logo_white.png" alt=""/>
      </div>

      <lbrx-kiosk-popup v-if="showTermsAndConditions" :terms="isTerms" :privacy="isPrivacy" @close="showTermsAndConditions = false" v-model="showTermsAndConditions" :closable="isClosable" :box-height="boxHeight" :box-background="boxBackground"></lbrx-kiosk-popup>

  </div>
</template>
<script>
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";

export default {
  name: 'TheFooter',
  props: {
    color: {type: String, required: true},
    background: {type: String, required: true},
    modelValue: {type: Boolean, required: true},
    openTerms: {type: Boolean, required: false,}
  },
  components: {LbrxKioskPopup},
  data() {
    return {
      isTerms: true,
      isPrivacy:false,
      boxHeight:'80%',
      isClosable: true,
      boxBackground: this.color,
      showTermsAndConditions: false,
      showLanguageSelect: false,
      showAssistance: false,
    }
  }
}
</script>
<style scoped>
.labelsContainer{
    display: flex;
}
.terms {
    width: fit-content;
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.footerLabel{
    cursor: pointer;
    margin: auto;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    /* identical to box height */


    color: #FFFFFF;
}
.poweredByContainer{
    margin-right: 3vmin;
    display: flex;
}
.poweredBy{
    margin: auto;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    /* identical to box height */


    color: #FFFFFF;
}

.footer-logo {
  width: 10vmin;
  margin-top: auto;
  margin-bottom: auto;
}

.footer-items {
  display: flex;
}

.footer-container {
  width: 100% !important;
  background: v-bind(background) !important;
  display: flex;
  height: 30px !important;

}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  color: red !important;
  font-weight: bold!important;
}
</style>