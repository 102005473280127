<template>
    <div id="kioskPopup" :class="{'right-class': $i18n.locale === 'ar'}" v-if="modelValue" class="popup-container">
        <Transition name="fade">
            <div v-if="showPopup" :style="{'height': terms  || privacy ? '90%': ''}" class="popup-box">
                <div class="close-popup-container" :style="{'justify-content': terms || privacy ? 'space-between !important' : ''}">

                    <div v-if="privacy || terms" class="popup-title">
                        <span v-if="privacy">{{ $t('Kiosk.footerSecond') }}</span>
                        <span v-else>{{ $t('Kiosk.footerFirst') }}</span>
                    </div>
                    <div @click="closeThis()" v-if="closable" class="close-popup">
                        <div style="margin: auto">X</div>
                    </div>
                </div>
                <div style="margin-top: -3vh;overflow-y: hidden !important;" class="popup-body" v-if="bookingConfirmation">
                    <div style="display: flex;justify-content: center;width: 100%">
                        <img style="width: 12%" src="../assets/icons/tick-mark.svg"/>
                    </div>
                    <div style="margin-top: 2vh" class="bookingDone-title">
                        {{ $t('Kiosk.bookingDoneTitle') }}
                    </div>
                    <div class="bookingDone-text">
                        {{ $t('Kiosk.bookingDoneText') }}
                    </div>
                </div>
                <div style="margin-top: -5vh;overflow-y: hidden !important;" class="popup-body" v-if="phoneNumberConfirmation">
                    <div @click="closeThis()" style="display: flex;justify-content: center;width: 100%">
                        <img style="width: 40%" src="../../public/images/sim-card.png"/>
                    </div>
                    <div style="margin-top: 2vh" class="simcard-title">
                        {{ $t('Kiosk.enterPhoneNumber') }}
                    </div>
                </div>
                    <div v-else-if="!loading && !terms && !privacy" class="popup-body">
                        <div v-if="icon" class="d-flex justify-content-center">
                            <div class="spinner-border" v-if="icon === 'loader'" :style="{'color':iconColor}" style="height: 15vmin;width: 15vmin;font-size: 6vmin" role="status">
                            </div>
                            <div v-else-if="icon === 'error'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="100" height="100">
                                    <path d="M86 96H10C4.488 96 0 91.512 0 86V10C0 4.488 4.488 0 10 0H86C91.512 0 96 4.488 96 10V86C96 91.512 91.512 96 86 96ZM10 4C6.692 4 4 6.692 4 10V86C4 89.308 6.692 92 10 92H86C89.308 92 92 89.308 92 86V10C92 6.692 89.308 4 86 4H10Z" :fill="iconColor"/>
                                    <path d="M48 76C44.692 76 42 73.308 42 70C42 66.692 44.692 64 48 64C51.308 64 54 66.692 54 70C54 73.308 51.308 76 48 76ZM48 68C46.896 68 46 68.896 46 70C46 71.104 46.896 72 48 72C49.104 72 50 71.104 50 70C50 68.896 49.104 68 48 68Z" :fill="iconColor"/>
                                    <path d="M48 56C46.896 56 46 55.104 46 54V22C46 20.896 46.896 20 48 20C49.104 20 50 20.896 50 22V54C50 55.104 49.104 56 48 56Z" fill="#EB5757"/>
                                </svg>
                            </div>
                        </div>
                        <div v-if="content && content!== ''" class="popupContent">
                            {{content}}
                        </div>
                    </div>
                    <div style="margin-top: 1vmin" class="popup-body" :style="{'height': confirmationRequired ? '70% !important' :'89% !important'}" v-else-if="terms">

                        <lbrx-terms-and-conditions></lbrx-terms-and-conditions>
                        <div v-if="confirmationRequired" class="validateTerms">
                      <div class="checkbox-container">
                          <lbrx-checkbox :label="termsLabel" :model-value="boxChecked" v-model="boxChecked" :checked-color="mainTheme.primaryColor"></lbrx-checkbox>
                      </div>
                          <lbrx-button id="acceptTermsButton" v-model="buttonDisabled" class="margin-bottom" @clicked="$emit('validateTerms')"  :label="$t('Kiosk.acceptButton')" color="#FFFFFF"
                                         background="#008FCA" height="6vmin" width="20%" font-size="1.1"></lbrx-button>
                        </div>
                    </div>
                <div style="margin-top: 1vmin" :style="{'height': confirmationRequired ? '70% !important' :'89% !important'}" class="popup-body" v-else>
                    <lbrx-privacy-policy></lbrx-privacy-policy>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>

import LbrxTermsAndConditions from "@/components/LbrxTermsAndConditions.vue";
import LbrxButton from "@/components/LbrxButton.vue";
import router from "@/router";
import LbrxPrivacyPolicy from "@/components/LbrxPrivacyPolicy.vue";
import {kioskService} from "@/_services";
import LbrxCheckbox from "@/components/LbrxCheckbox.vue";

export default {
    name: 'LbrxKioskPopup',
    components: {LbrxCheckbox, LbrxPrivacyPolicy, LbrxButton, LbrxTermsAndConditions},
    props: {
        modelValue:Boolean,
        closable:{required:true, type:Boolean},
        content:{required:false, type:String},
        icon:{required:false, type:String},
        textColor:{required:false, type:String},
        iconColor:{required:false, type:String},
        boxHeight:{required:true, type:String},
        boxBackground:{required:true, type:String},
        terms:{required:false, type:Boolean},
        privacy:{required:false, type:Boolean},
        bookingConfirmation:{required:false, type:Boolean},
        phoneNumberConfirmation:{required:false, type:Boolean},
        confirmationRequired:{required:false, type:Boolean},
    },
    emits: ['validateTerms', 'bookingDone'],
    computed: {
      buttonDisabled() {
        return !this.boxChecked;
      }
    },
    data() {
        return {
            termsLabel: '',
            boxChecked: false,
            showPopup:false,
            loading:false,
            closeTimeout:null,
            mainTheme:null
        }
    },
    methods: {
        closeThis() {
            if(this.bookingConfirmation) {
                const ln = JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).default_language;
                window.localStorage.setItem('Language', ln)
                clearTimeout(this.closeTimeout);
                this.$emit('bookingDone');
            } else {
                if(!this.closable)
                    return;
                this.showPopup = false;
                setTimeout(()=>{
                    this.$emit('close');
                },400)
            }
        },
    },
    created() {
        this.mainTheme = JSON.parse(window.localStorage.getItem('theme'));
        this.termsLabel = this.$t("Kiosk.iAcceptText");
        if(this.mainTheme.acceptLabel && this.mainTheme.acceptLabel.length) {
          this.termsLabel = this.mainTheme.acceptLabel;
        }
        document.addEventListener('keyup',(event)=>{
            event.key === 'Escape' && this.closable && !this.confirmationRequired ? this.closeThis():null;
        });
        setTimeout(()=>{
            document.addEventListener('click',()=>{
                (this.closable && !this.confirmationRequired) || this.bookingConfirmation ? this.closeThis():null;
            });
        },1500);
        this.loading = false;
        setTimeout(()=>{
            this.showPopup = true;
        },1);
        if(this.bookingConfirmation) {
            this.closeTimeout = setTimeout(()=>{
                const ln = JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).default_language;
                window.localStorage.setItem('Language', ln)
                this.$emit('setDateToNull');
                this.$emit('bookingDone');
            },this.mainTheme.printTicket.printTicketTimeout);
        }
    },
}
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.checkbox-container{
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.bookingDone-title{
    font-family: 'Exo 2',serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: #00CC6A;
    padding-left: 30px;
    padding-right: 30px;

}
.simcard-title{
    font-family: 'Exo 2',serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: #4f52ff;
    padding-left: 30px;
    padding-right: 30px;

}
.bookingDone-text {
    font-family: 'Exo 2',serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color:#BDBDBD;
    padding-left: 55px;
    padding-right: 55px;

}
.validateTerms{
    position: fixed;
    width: 70%;
    bottom: 0;
    padding-bottom: 0.5vh;
    margin-bottom: 3vh;
    background: white;
}
.popupContent{
    text-align: center;
    margin-top: 5vmin;
    padding-bottom: 15px;
    width: 100%;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2vw;
    line-height: 2vw;
    color: v-bind(textColor);
    overflow: hidden;
}
.popup-container{
    background: rgba(0,0,0,0.7);
    display: flex !important;
    justify-content: center !important;
}
.popup-box {
    margin: auto;
    overflow-y: hidden !important;
    width: 70%;
    height: v-bind(boxHeight);
    background: v-bind(boxBackground);
}
.popup-body {
    overflow-y: auto !important;
    margin-top: 1vmin;
    height: 80% !important;
}
.close-popup{
    background: #A3A3A3;
    height: 50px;
    width: 50px;
    font-weight: 700;
    color: v-bind(textColor);
    font-size: 30px;
    line-height: 30px;
    display: flex;
}
.popup-title{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.6vw;
    line-height: 3vw;
    text-align: center;
    padding: 2vmin;
    margin-left: 3vmin;
    color: #000000;
}
</style>