<template>
<div class="phone-number-input-container">
    <div class="input-box" >
        <input type="text" v-model="getFullNumber" placeholder="+216 -- --- ---" readonly class="phone-number-input" />

        <div class="pad-container">
                    <div v-for="(index) in 9" :key="index" @click="clickPad(index)" class="pad-element"><div style="margin:auto">{{ index }}</div></div>
                    <div @click="clickPad(-2)" class="pad-element secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                            <path d="M43 48H5C2.242 48 0 45.756 0 43V5C0 2.244 2.242 0 5 0H43C45.758 0 48 2.244 48 5V43C48 45.756 45.758 48 43 48ZM5 2C3.346 2 2 3.346 2 5V43C2 44.654 3.346 46 5 46H43C44.654 46 46 44.654 46 43V5C46 3.346 44.654 2 43 2H5Z" :fill="getnumBackground"/>
                            <path d="M37 36H19C18.718 36 18.45 35.88 18.26 35.672L8.25999 24.672C7.91399 24.29 7.91399 23.708 8.25999 23.326L18.26 12.326C18.45 12.12 18.718 12 19 12H37C38.654 12 40 13.346 40 15V33C40 34.654 38.654 36 37 36ZM19.442 34H37C37.55 34 38 33.552 38 33V15C38 14.448 37.55 14 37 14H19.442L10.352 24L19.442 34Z" :fill="getnumBackground"/>
                            <path d="M33 29C32.744 29 32.488 28.902 32.292 28.708L24.292 20.708C23.902 20.318 23.902 19.684 24.292 19.294C24.682 18.904 25.316 18.904 25.706 19.294L33.706 27.294C34.096 27.684 34.096 28.318 33.706 28.708C33.512 28.902 33.256 29 33 29Z" :fill="getnumBackground"/>
                            <path d="M25 29C24.744 29 24.488 28.902 24.292 28.708C23.902 28.318 23.902 27.684 24.292 27.294L32.292 19.294C32.682 18.904 33.316 18.904 33.706 19.294C34.096 19.684 34.096 20.318 33.706 20.708L25.706 28.708C25.512 28.902 25.256 29 25 29Z" :fill="getnumBackground"/>
                        </svg>
                    </div>
                    <div @click="clickPad(0)" class="pad-element"><div style="margin:auto">0</div></div>
                    <div @click="clickPad(-1)" class="pad-element secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                            <path d="M43 48H5C2.242 48 0 45.756 0 43V5C0 2.244 2.242 0 5 0H43C45.758 0 48 2.244 48 5V43C48 45.756 45.758 48 43 48ZM5 2C3.346 2 2 3.346 2 5V43C2 44.654 3.346 46 5 46H43C44.654 46 46 44.654 46 43V5C46 3.346 44.654 2 43 2H5Z" :fill="getnumBackground"/>
                            <path d="M29 32H25C22.242 32 20 29.756 20 27V21C20 18.244 22.242 16 25 16H29C29.552 16 30 16.448 30 17C30 17.552 29.552 18 29 18H25C23.346 18 22 19.346 22 21V27C22 28.654 23.346 30 25 30H29C29.552 30 30 30.448 30 31C30 31.552 29.552 32 29 32Z" :fill="getnumBackground"/>
                            <path d="M29 25H21C20.448 25 20 24.552 20 24C20 23.448 20.448 23 21 23H29C29.552 23 30 23.448 30 24C30 24.552 29.552 25 29 25Z" fill="white"/>
                            <path d="M13 32H9C8.448 32 8 31.552 8 31V17C8 16.448 8.448 16 9 16H13C15.758 16 18 18.244 18 21V27C18 29.756 15.758 32 13 32ZM10 30H13C14.654 30 16 28.654 16 27V21C16 19.346 14.654 18 13 18H10V30Z" :fill="getnumBackground"/>
                            <path d="M41 32H37C34.242 32 32 29.756 32 27V17C32 16.448 32.448 16 33 16C33.552 16 34 16.448 34 17V27C34 28.654 35.346 30 37 30H41C41.552 30 42 30.448 42 31C42 31.552 41.552 32 41 32Z" :fill="getnumBackground"/>
                        </svg>
                    </div>
        </div>
         <lbrx-button id="smsButton" v-model="isDisabled" :color="noAnonym ? themeTemplate.numPad.anonymSendButtonColor: themeTemplate.numPad.sendButtonColor" :label="$t('Kiosk.sendButton')" @clicked="validatePhoneNumber" :background="noAnonym ? themeTemplate.numPad.anonymSendButtonBackground: themeTemplate.numPad.sendButtonBackground" width="100%" :height="'10vmin'" font-size="1.5">
         </lbrx-button>
    </div>
</div>
</template>

<script>
import colorShader from "@/_helpers/color-shader";
import LbrxButton from "@/components/LbrxButton.vue";

export default {
    name: 'LbrxPhoneNumberInput',
    components: {LbrxButton},
    props: {
        themeTemplate: {required: true, type: Object},
        modelValue: {required: true, type: Boolean},
        noAnonym: {required: false, type: Boolean},
    },
    emits: ['sendTicket', 'cancelAutoAnonymous'],
    data() {
        return {
            phoneNumber: '',
            prefix:'+216',
        }
    },
    methods: {
        validatePhoneNumber() {
            if(this.phoneNumber.length !== 8) {
                //fire error
                return;
            }
            this.$emit('sendTicket',{country_prefix: this.prefix, phone_number: this.phoneNumber, internal_id:null});
        },
        clickPad(num) {
          this.$emit("cancelAutoAnonymous");
            if (this.isDisabled)
                return;
            if(num === -1) {
                this.phoneNumber = "";
            } else if(num === -2) {
                this.phoneNumber = this.phoneNumber.substring(0, this.phoneNumber.length-1);
            } else {
                if(this.phoneNumber.length < 8)
                    this.phoneNumber+=num;
            }
        }
    },
    computed: {
        getNumPrimary() {
            return this.themeTemplate.numPad.numPrimary
        },
        getNumSecondary() {
            return this.themeTemplate.numPad.numSecondary
        },
        getnumBackground() {
            return this.themeTemplate.numPad.numBackground
        },
        isDisabled() {
            return this.modelValue;
        },
        getFullNumber() {
          if(this.phoneNumber.length === 0 || this.phoneNumber.length > 8)
              return '';
          let num = this.phoneNumber;
          let fullNum = this.prefix;
          while (num.length){
              if(fullNum.length === 4 || fullNum.length === 7 || fullNum.length === 11)
                  fullNum+=" ";
              fullNum+=num[0];
              num = num.substring(1);
          }
          while (fullNum.length < 15) {
              if(fullNum.length === 4 || fullNum.length === 7 || fullNum.length === 11)
                  fullNum+=" ";
              fullNum+='-';
          }
          return fullNum;
      },
    }
}
</script>

<style scoped>

.pad-container{
    margin-top: 1.5vmin;
    width:100%;
    height: 100%;
    padding: 4vmin;
    justify-content: center;
    display: grid;
    grid-template-columns: auto auto auto;
}
.pad-element{

    height: 10vh;
    width: 10vh;
    cursor: pointer;
    background: v-bind(getnumBackground);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 1vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 700;
    font-size: 7vh;
    line-height: 7vh;
    text-align: center;
    display: flex;

    color: v-bind(getNumPrimary);

}
.secondary{
    background: v-bind(getNumSecondary) !important;
    display: flex;
    padding: 1vh;
    justify-content: center;
}
.phone-number-input{
    background: v-bind(getnumBackground);
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 600;
    font-size: 4.5vh;
    padding: 1vh;
    text-align: center;
    height: fit-content;
    width: 100%;
    color: v-bind(getNumPrimary);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none !important;
}
.phone-number-input::placeholder{
    font-weight: 600;
    font-size: 4.5vh;
    text-align: center;
    color: #A3A3A3;
}
.input-box{
    display: flex;
    height: 100%;
    width: 80%;
    flex-direction: column;
    justify-content: space-between;
}
.phone-number-input-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
</style>