<template>
  <div class="anonContainer">
    <div :class="{'right-class': $i18n.locale === 'ar'}"  class="anonTitle">
        {{ $t('Kiosk.anonTitleFirstPart') }} <span style="font-weight: 600">“{{ $t('Kiosk.anonButton') }}”</span>{{ $t('Kiosk.anonTitleSecondPart') }}
    </div>
      <lbrx-button id="anonButton" v-model="isDisabled" :class="{'right-class' : $i18n.locale === 'ar'}" :color="themeTemplate.textColor" :countdown="hibernateCountdown" :label="$t('Kiosk.anonButton')" @clicked="validateTicket()" :background="themeTemplate.printTicket.anonButtonBackground" width="90%" :height="'10vmin'" font-size="1.5">
      </lbrx-button>

  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";

export default {
    name:'LbrxAnonymousTicketPrint',
    components: {LbrxButton},
    props: {
        cancelAutoAnonym: {required: true, type: Boolean},
        themeTemplate: {required: true, type: Object},
        modelValue: {required: true, type: Boolean},
    },
  watch: {
    cancelAutoAnonym(newCancelAutoAnonym, oldCancelAutoAnonym) {
      if(newCancelAutoAnonym) {
        this.allowAutoPrint = false;
        clearTimeout(this.autoAnonymous);
      }
    },
  },
    data() {
      return {
        allowAutoPrint:true,
        autoAnonymous:null,
        hibernateCountdown: null,
        countdown: 0,
        hibernateInterval: null,
      }
    },
    emits: ['sendTicket', 'sending', 'cancel_idle', 'start_idle'],
    computed: {
      isDisabled() {
          return this.modelValue || this.countdown > 0;
      },
      getTextColor() {
          return this.themeTemplate.textColor;
      }
    },
    created() {
      if(this.anonymousHibernate()) {
        this.$emit("cancel_idle");
        const lastTicketDate = new Date(window.localStorage.getItem("lastAnonymousTicket"));
        this.countdown = this.getHibernateInterval() - (new Date() - lastTicketDate);
        this.updateHibernateCounter();
        this.hibernateInterval = setInterval(()=> {
          this.countdown-=1000;
          if (this.countdown <= 0) {
            clearInterval(this.hibernateInterval);
            this.$emit("start_idle");
            this.startAutoPrint();
            this.hibernateCountdown = null;
            return;
          }
          this.updateHibernateCounter();
        }, 1000)
      } else {
        this.startAutoPrint();
      }
    },
  methods: {
      startAutoPrint() {
        if(this.themeTemplate.onPrintTicket != null) {
          if (this.themeTemplate.onPrintTicket.status && this.allowAutoPrint) {
            clearTimeout(this.autoAnonymous);
            this.autoAnonymous = setTimeout(() => {
              this.validateTicket();
            }, this.themeTemplate.onPrintTicket.timeout)
          }
        }
      },
    updateHibernateCounter() {
      const minutes = Math.floor(this.countdown / 60000);
      const seconds = Math.floor((this.countdown % 60000) / 1000);
      this.hibernateCountdown = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    },
      validateTicket() {
        clearTimeout(this.autoAnonymous);
        this.$emit('sendTicket');
      },
      getHibernateInterval() {
        let hibernateDuration = parseInt(process.env.VUE_APP_DEFAULT_ANONYMOUS_HIBERNATE, 10);
        if(this.themeTemplate.anonymousHibernateInterval != null) {
          hibernateDuration = this.themeTemplate.anonymousHibernateInterval.timeout;
        }
        return hibernateDuration;
      },
      anonymousHibernate() {
        if(this.themeTemplate.anonymousHibernateInterval != null) {
            if(this.themeTemplate.anonymousHibernateInterval.status) {
            const lastTicketDate = new Date(window.localStorage.getItem("lastAnonymousTicket"));
            if(lastTicketDate != null) {
              const now = new Date();
              const timeDifference = now - lastTicketDate;
              if (timeDifference < this.getHibernateInterval()) {
                return true;
              }
            }
          }
        }
        this.hibernateCountdown = "";
        return false;
      },
      cancel() {
          this.autoAnonymous = null;
          this.$emit('sending');
          window.location.reload();
      }
    }
}
</script>

<style scoped>
  .anonContainer{
      width: 100%;

      margin-bottom: -20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  .anonTitle{
      margin-bottom: 2vmin;
      font-family: 'Exo 2',serif;
      font-style: normal;
      font-weight: 300;
      font-size: 2vw;
      line-height: 2.5vw;
      text-align: left;
      color: v-bind(getTextColor);
  }
</style>