<template>
<div class="button-container">
    <div class="form-check checkbox-container">
        <input id="lbxCheckbox" :style="{'margin-right': $i18n.locale !== 'ar' ? '15px': '', 'margin-left': $i18n.locale === 'ar' ? '15px': ''}" class="input-style" type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)"/>
        <label class="form-check-label" for="lbxCheckbox">
            {{label}}
        </label>
    </div>
</div>
</template>
<script>
export default {
    name: 'LbrxCheckbox',
    props: {
      label:{
          required: true,
          value: String,
      },
      checkedColor: {required: true, type: String},
      modelValue:Boolean,
    },
    emits: ['update:modelValue'],
}
</script>

<style scoped>
.form-check-label{
    font-family: 'Exo 2',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    /* identical to box height */
    /* Medium Gray */
    color: #737070;
}
.checkbox-container{
    height: fit-content;
    display: flex;
    padding-left: 0 !important;
}
input[type=checkbox]
{
    cursor: pointer;
    -webkit-appearance: none;
    width: 35px;
    height: 35px;
    border: 0.2vmin solid #737070;
}
input[type=checkbox]:checked {
    background-color: v-bind(checkedColor);
}
.button-container {
    text-align: left;
    height: 40px;
}
.input-style{
    border: 1px solid #E4E4E4;
}
</style>