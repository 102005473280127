export default {
    mounted(el:any, binding:any) {
        let pressTimer:any = null;
        const longPressDuration:any = 500; // Define your long press duration here (in milliseconds)
        let isClick = true; // Flag to track if click event should be triggered

        const start = (event:any) => {
            if (event.type === 'click' && event.button !== 0) {
                return;
            }

            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    isClick = false; // Set the flag to false for long press
                    binding.value(event);
                }, longPressDuration);
            }

            // Prevent the default behavior of the right-click context menu
            event.preventDefault();
        };

        const cancel = () => {
            if (pressTimer !== null) {
                clearTimeout(pressTimer);
                pressTimer = null;

                if (isClick) {
                    // Only trigger the click event if isClick flag is true
                    el.click();
                }
            }

            isClick = true; // Reset the flag
        };

        el.addEventListener('mousedown', start);
        el.addEventListener('touchstart', start);
        el.addEventListener('click', cancel);
        el.addEventListener('mouseout', cancel);
        el.addEventListener('touchend', cancel);
        el.addEventListener('touchcancel', cancel);
    },
};
