<template>
  <div class="fifthThemeContainer">
    <lbrx-kiosk-header v-if="!popup" :logo="themeTemplate.header.logo" :hasBorderRadius="themeTemplate.header.borderRadius"  v-model="isDisabled" :primaryColor="themeTemplate.primaryColor" :secondaryColor="themeTemplate.secondaryColor" :text-color="themeTemplate.textColor" :header-background="themeTemplate.header.headerBackground"></lbrx-kiosk-header>
    <div v-if="!popup"  class="titleContainer">
      <div class="title">
        {{ $t('Kiosk.servicesTitle') }}
      </div>
    </div>
    <div class="mainContainer">
      <div :class="{'right-class': $i18n.locale === 'ar'}" class="row">
        <div :class="{'col-6': popup || servicesPage.length < 3, 'col-4': !popup && servicesPage.length > 2}" v-for="(service, index) in servicesPage" :id="'service'+service.id" :key="index" >
          <div :class="{'right-class': $i18n.locale === 'ar'}" :style="{'background': service.checked ? themeTemplate.services.selectedServiceBackgroundColor : '', 'opacity': service.disabled ? '0.7':'1'}" :title="service.title" v-long-press:@click="()=>handleLongPress(service)" @mouseup="handleClick(service)" @touchend="()=>handleTouch(service)" class="singleService">
            <div v-if="service.translations && JSON.parse(service.translations)[$i18n.locale] != null" :style="{'color': service.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(JSON.parse(service.translations)[$i18n.locale]), 'line-height': getFontSize(JSON.parse(service.translations)[$i18n.locale])}" class="labelContainer" :class="{'right-class': $i18n.locale === 'ar'}">{{JSON.parse(service.translations)[$i18n.locale]}}  <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked && service.qte">(x{{service.qte}})</span></div>
            <div v-else-if="service.title" :style="{'color': service.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(service.title) , 'line-height': getFontSize(service.title)}" class="labelContainer">{{service.title}}  <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked && service.qte">(x{{service.qte}})</span></div>
          </div>
        </div>
      </div>
    </div>
    <lbrx-services-paginator v-if="pagesCount > 1 && allowPagination" @next-page="nextPage" @prev-page="prevPage" @set-page="setPage" :theme-template="themeTemplate" v-model="currentPage" :pages-count="pagesCount"></lbrx-services-paginator>
    <div v-else class="paginator-holder">

    </div>

    <span v-if="!popup && showNextButton">
  <div class="nextButtonContainer" :style="{'margin-bottom': popup ? '8.5vmin': '2vmin'}">
      <lbrx-button id="validateButton" v-model="isDisabled"
                   @clicked="$emit('validate')" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="phoneNumberNotAllowed ? $t('Kiosk.printTicketButton') :$t('Kiosk.nextButton')" :color="themeTemplate.services.mainButtonTextColor"
                   :background="themeTemplate.services.mainButtonBackground" height="8vmin" width="31%" font-size="1.3"></lbrx-button>
  </div>
    </span>
    <div v-if="!popup"  class="footer-container">
      <the-footer :color="themeTemplate.textColor" v-model="onLine" :background="themeTemplate.footer.footerBackground"></the-footer>
    </div>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxKioskHeader from "@/components/LbrxKioskHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxServicesPaginator from "@/components/LbrxServicesPaginator.vue";
import longPressDirective from '../_helpers/longPressDirective';

export default {
  name: "LbrxKioskSixthTheme",
  components: {LbrxServicesPaginator, TheFooter, LbrxKioskHeader, LbrxButton},
  props: {
    themeTemplate: {required: true, type: Object},
    services: {required: true, type: Array},
    modelValue: {required: true, type: Boolean},
    popup: {required: true, type: Boolean},
  },
  emits: ['validate', 'checkService', 'long-press'],
  directives: {
    'long-press': longPressDirective,
  },
  data() {
    return {
      allowPagination:false,
      language: 'fr',
      currentPage:1,
      pagesCount:0,
      itemsPerPage:12,
      onLine: navigator.onLine,
    }
  },
  created() {
    if(this.themeTemplate.allowPagination != null)
      this.allowPagination = this.themeTemplate.allowPagination;
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    if(this.popup) {
      this.itemsPerPage = 2;
    } else {
      this.itemsPerPage = Math.floor(window.innerHeight / 180) *3;
      console.log(this.itemsPerPage);
    }
    this.pagesCount = Math.ceil(this.services.length / this.itemsPerPage);
  },
  methods: {
    getFontSize(label) {
      if(label.length > 100) {
        return "15px";
      }
      if(label.length > 50) {
        return "18px";
      }
      return "22px";
    },
    handleLongPress(service) {
      console.log(service);
      this.longPress = true;
      this.$emit('long-press', service);
    },
    handleClick(service) {
      this.$emit('checkService', service);
    },
    handleTouch(service) {
      if(this.longPress) {
        this.longPress = false;
        return;
      }
      this.$emit('checkService', service);
    },
    setPage(pageNumber) {
      if(this.currentPage !== pageNumber)
        this.currentPage = pageNumber;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.pagesCount) {
        this.currentPage++;
      }
    },
    changeLanguage() {
      if(this.language === 'fr')
        this.language = 'ar';
      else
        this.language = 'fr';
    },

  },
  computed: {
    getHeight() {
      if(this.allowPagination) {
        return "76px";
      }
      return (window.innerHeight+100)/(this.services.length) <= 76 ? (window.innerHeight+100)/(this.services.length)+"px" : "76px";
    },
    showNextButton () {
      return (+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services || JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket);
    },
    phoneNumberNotAllowed() {
      return !JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket;
    },
    getBackground() {
      if(this.popup)
        return "#FFFFFF";
      if(this.themeTemplate.services.background.includes('base64')) {
        return "url(" +this.themeTemplate.services.background+")";
      }
      return this.themeTemplate.services.background;
    },
    servicesPage() {
      if(this.allowPagination) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.services.slice(startIndex, startIndex + this.itemsPerPage);
      }
      return this.services;
    },
    isDisabled() {
      return this.modelValue;
    },
    getServiceBg() {
      if(this.themeTemplate.services.serviceBackground.includes('base64')) {
        return `url(${this.themeTemplate.services.serviceBackground})`;
      }
      else if(this.popup && this.themeTemplate.services.background.includes('base64')) {
        return `url(${this.themeTemplate.services.background})`;
      }
      return this.themeTemplate.services.serviceBackground;
    },
    getServiceTextColor() {
      if(this.popup && this.themeTemplate.services.background.includes('base64')) {
        return this.themeTemplate.services.titleColor;
      }
      return this.themeTemplate.services.serviceTextColor;
    },
    getTitleColor() {
      return this.themeTemplate.services.titleColor;
    }
  }
}
</script>

<style scoped>
.servicesPaginationsContainer{
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paginator-holder{
  height: 3vmin;
}
.footer-container {
  width: 100% !important;
  height: auto;
}
.titleContainer{
  color:v-bind(getTitleColor);
  font-family: 'Exo 2',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.3vw;
  line-height: 2vmin;
  margin: 5vmin;
}
.mainContainer{

  padding: 1vmin 20px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100% !important;
}
.labelContainer{
  font-family: 'Exo 2',serif;
  font-style: normal;
  font-weight: 400;
  color: v-bind(getServiceTextColor);
  text-align: left;
  margin: auto 2vmin;
  padding-bottom: 10px;
  padding-top: 5px;
}
.singleService{
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  cursor: pointer;
  height: v-bind(getHeight);
  margin-bottom: 2vmin;
  width: 100%;
  /* identical to box height */
  background: v-bind(getServiceBg);
  border-radius: 0px;
}
.fifthThemeContainer{
  background: v-bind(getBackground) no-repeat;
  background-size: cover;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nextButtonContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.5vh;
  height: 10vh;
  margin-bottom: 2vmin;
}
</style>