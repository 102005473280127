import {createApp} from 'vue'
import 'mdb-vue-ui-kit/css/mdb.min.css'
import App from './App.vue'
import router from './router'

import store from "./store";
import Echo from "laravel-echo";
import {ApiConfigs, authHeader} from "@/_helpers";
import {kioskService} from "@/_services/kiosk.service";
import {createI18n} from "vue-i18n";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


import fr from "./locales/fr.json";
import en from "./locales/en.json";
import ar from "./locales/ar.json";


const echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: (process.env.VUE_APP_EDIT_HOST === "true" && localStorage.getItem('host') != null) ? localStorage.getItem('ws') : process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: false,
    authEndpoint: ApiConfigs.broadcast_url + ApiConfigs.pusher.auth,
    enabledTransports: ["ws", "wss"],
    auth: {
        headers: {...authHeader()},
    },
});
if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'fr')
}
const i18n = createI18n({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locale: localStorage.getItem('Language'),
    fallbackLocale: "en",
    messages: {fr, en, ar},
});
const app = createApp(App).use(store).use(router).use(i18n)


if (localStorage.getItem('kiosk') !== null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.localStorage.setItem('theme', JSON.stringify(JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config_v2)));
    app.provide('kiosk', JSON.parse(localStorage.getItem('kiosk') || ''))
    echo.channel("private-kiosk-" + JSON.parse(localStorage.getItem('kiosk') || "").id)
        .listen(
            ".kiosk-refresh",
            function () {
                kioskService
                    .refresh()
                    .then(() => {
                        kioskService.loadServices()
                            .then(() => {
                                location.reload()
                            })
                            .catch((err) => {
                                console.log(err);
                            })
                    })
                    .catch(
                        function (err: any) {
                            console.log(err)
                        }
                    );
            }
        ).listen(
        ".kiosk-logout",
        function () {
            window.localStorage.setItem("archiveToken", "" + window.localStorage.getItem("token"));
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("kiosk");
            window.localStorage.removeItem("business");
            window.localStorage.removeItem("services");
            window.localStorage.removeItem("theme");
            window.localStorage.removeItem("queues");
            router.push({name: "login"});
        }.bind(this)
    ).listen(
        ".kiosk-back-to-v1",
        () => {
            window.location.replace(process.env.VUE_APP_V1_URL + "/to-v1");
        }
    ).listen(
        ".maintenance-change",
        function () {
            kioskService
                .refresh()
                .then(() => {
                    kioskService.loadServices()
                        .then(() => {
                            location.reload()
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                })
                .catch(
                    function (err: any) {
                        console.log(err)
                    })
        }.bind(this)
    );
} else {
   // window.localStorage.clear();
    //window.location.reload();
}

app.component('VueDatePicker', VueDatePicker);
app.provide('echo', echo)

app.mount('#app')