<template>
    <div class="calendar-container">
        <div class="calendar-title">
            {{ $t('Kiosk.rndvSingleTitle') }}
        </div>
        <div :class="{'right-class': $i18n.locale === 'ar'}" class="calendar">
            <div class="calendar-box">
                <div class="row cont">
                    <div class="col-4" ref="calendarContainer" style="height: fit-content !important">
                        <VueDatePicker @update:model-value="getAvailableTimes" :day-names="dayNames" :locale="locale" v-model="date" inline auto-apply :min-date="new Date()" :enable-time-picker="false"></VueDatePicker>
                    </div>
                    <div class="col-8" :style="{ height: calendarHeight }">
                        <div style="overflow-y:auto !important;padding-top: 20px;padding-bottom: 20px" :style="{'margin-left': $i18n.locale !== 'ar' ? '2vmin': '', 'margin-right': $i18n.locale === 'ar' ? '2vmin': '' }" class="times-container">
                            <LbrxBookingTimePicker @time-selected="selectTime" :times="times" v-model="loadingSlots" :themeTemplate="themeTemplate"></LbrxBookingTimePicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nextButtonContainer">
            <lbrx-button id="rndvNextButton" v-model="isDisabled"
                         @clicked="validateDate" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.nextButton')" :color="themeTemplate.booking.timeSlotBackground"
                         :background="themeTemplate.booking.calendarBackground" height="9vmin" width="35%" font-size="1.5"></lbrx-button>
        </div>
        <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable" :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor" :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>

    </div>
</template>

<script>

import LbrxButton from "@/components/LbrxButton.vue";
import moment from "moment";
import {kioskService} from "@/_services";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
import LbrxBookingTimePicker from "@/components/LbrxBookingTimePicker.vue";
export default {
    name:"LbrxBookingSecondTheme",
    components: {LbrxBookingTimePicker, LbrxKioskPopup, LbrxButton},
    data() {
        return {
            date:null,
            selectedTime:null,
            times: [],
            calendarWidth: '',
            calendarHeight: '',
            loadingSlots: false,
            services: [],
            boxHeight:'40%',
            boxContent: '',
            boxIcon: 'loader',
            boxTextColor: '',
            isClosable: false,
            boxBackGround: "#FFFFFF",
            boxIconColor: this.themeTemplate.secondaryColor,
            showPopup: false,
            locale:this.$i18n.locale,
        }
    },
    emits: ['validate'],
    props: {
        themeTemplate: {required: true, type:Object},

    },
    mounted() {
        if(this.$i18n.locale === 'ar')
            this.locale = this.$i18n.locale+"-tn";
        setTimeout(()=>{
            this.setDimentions();
            this.getAvailableTimes(new Date());
        },100);
    },
    methods: {
        selectTime(time) {
          this.selectedTime = time;
        },
        showErrorPopup(errorText) {
            this.showPopup = false;
            this.boxIcon = 'error';
            this.isClosable = true;
            this.boxContent = errorText;
            this.boxTextColor = '#EB5757'
            this.boxBackGround = "#FFFFFF";
            this.boxIconColor = '#EB5757';
            this.boxHeight = "40%";
            this.showPopup = true;
        },
        validateDate() {
            if(this.selectedTime === null || this.date === null) {
                this.showErrorPopup("Veuillez selectionner une date valide...");
            }
            else {
                this.date = moment(this.date).format("YYYY-MM-DD");
                const dt = {};
                dt.date = this.date;
                dt.time =  this.selectedTime.slot;
                this.$emit('validate', dt);
            }
        },
        setDimentions() {
            if(this.$refs.calendarContainer) {
                this.calendarWidth = this.$refs.calendarContainer.offsetWidth+"px";
                this.calendarHeight =this.$refs.calendarContainer.offsetHeight+"px";
            } else {
                setTimeout(()=>{
                    this.setDimentions();
                },100)
            }
        },
        getAvailableTimes(dt) {
            this.times = [];
            this.loadingSlots = true;
            this.date = moment(dt).format("YYYY-MM-DD");
            const payload = {business_id: JSON.parse(window.localStorage.getItem('business')).id, date: moment(this.date).locale('en').format('YYYY-MM-DD'), department: JSON.parse(window.localStorage.getItem('business')).departments[0].id, user: null}
            kioskService.getAvailableSlots(payload)
                .then((res)=>{
                    this.times = res.slots
                    this.loadingSlots = false;
                })
                .catch((err)=>{
                    console.log(err);
                    this.loadingSlots = false;
                })

        }
    },
    computed: {
        dayNames() {
            moment.updateLocale(this.$i18n.locale, {
                    week: {
                        dow: 1, // Set the first day of the week to Monday (1)
                    },
            });
            return moment.weekdaysShort(true);
        },
        isDisabled() {
            return this.loadingSlots;
        },
        getBackground() {
            return this.themeTemplate.booking.background;
        },
        getTitleColor() {
            return this.themeTemplate.booking.titleColor;
        },
        getCalendarBackground() {
            return this.themeTemplate.booking.calendarBackground;
        },
        getTextColor() {
            return this.themeTemplate.booking.textColor;
        },
        getCalendarSlotBackground() {
            return this.themeTemplate.booking.calendarSlotBackground;
        },
        getTimeSlotBackground() {
            return this.themeTemplate.booking.timeSlotBackground;
        },
        getRotation() {
            if(this.$i18n.locale === 'ar') {
                return "rotate(180deg)";
            }
            return "rotate(0deg)";
        }
    },
}
</script>

<style scoped>

.calendar{
    width: 100%;
    display: flex;
    height: 51.6vh !important;
    justify-content: center;
}
.calendar-box {
    margin-top: 4vmin;
    height: 100% !important;
    width: 90%;
    display: flex;
    justify-content: center;
}
.calendar-container{
    padding: 5vmin;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.calendar-title{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 4.5vmin;
    line-height: 5vmin;
    /* identical to box height */
    color: v-bind(getTitleColor);
}

.nextButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 6vmin;
    margin-bottom: -6vmin;
}
.cont{
    margin-top: auto !important;
    margin-bottom: auto !important;
    width: 100%;
}
.times-container{
    background: v-bind(getCalendarBackground);
    overflow-y: hidden;
    padding: 3vmin;
    height: v-bind(calendarHeight) !important;
    justify-content: center;
    overflow-x: hidden;
}
</style>

<style>
:root{
    .dp__month_year_wrap{
        font-family: 'Inter',serif !important;
        font-size: 1.4em !important;
    }
    .dp__month_year_select{
        pointer-events: none !important;
    }
    .dp__calendar_header_item{
        font-family: 'Inter',serif !important;
        font-size: 0.8em !important;
        line-height: 1.8em !important;
        text-transform: capitalize !important;
    }
    .dp__cell_inner{
        font-family: 'Inter',serif !important;
        color: #9C9C9C;
    }
    .dp__theme_light{
        height: 322px !important;
        width: v-bind(calendarWidth) !important;
        --dp-text-color: v-bind(getTimeSlotBackground) !important;
    }
    .dp__month_year_col_nav{
        background: v-bind(getTimeSlotBackground) !important;
    }
    .dp__inner_nav svg {
        transform: v-bind(getRotation) !important;
    }
    .dp__month_year_col_nav:hover {
        opacity: 0.8 !important;
    }
    .dp__active_date {
        background: v-bind(getTimeSlotBackground) !important;
        color: v-bind(getTextColor) !important;
    }
    .dp__icon {
          fill: v-bind(getTextColor) !important;
        stroke: v-bind(getTextColor) !important;
    }
    .dp__theme_light{
        --dp-hover-color: transparent !important;
}
}
</style>