<template>
    <router-view></router-view>
</template>
<script>

import {kioskService} from "@/_services";

export default {
  data() {
    return {
      pingTimeout: null
    }
  },
  computed: {
    getScrollBarColor() {
      if(window.localStorage.getItem("theme"))
        return JSON.parse(window.localStorage.getItem("theme")).primaryColor;
      return "#008FCA";
    },
    getScrollBarBackground() {
      let background = "";
      if(window.localStorage.getItem("theme"))
        background = JSON.parse(window.localStorage.getItem("theme")).secondaryColor;
      else
        background = "#8A8A8A";
      const svgData = encodeURIComponent(
          `<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 43 100'><rect fill='${background}' width='47' height='100'/></svg>`
      );
      return `url("data:image/svg+xml;charset=utf8,${svgData}")`;
    },
  },
  methods: {
    startPinging(remainingTime) {
      console.log("ping int: "+process.env.VUE_APP_PING_INT)
      console.log("remaining: "+remainingTime)
      this.pingTimeout = setTimeout(()=> {
        window.localStorage.setItem("lastPing", new Date().toString());
        kioskService.ping({print_status: 1, paper_status: 1})
        this.startPinging(parseInt(process.env.VUE_APP_PING_INT));
      }, remainingTime);
    }
  },
  mounted() {
    clearTimeout(this.pingTimeout);
    let lastPingDate = new Date(window.localStorage.getItem("lastPing"));
    let remainingTime = parseInt(process.env.VUE_APP_PING_INT);
    if(lastPingDate == null) {
      lastPingDate = new Date();
      window.localStorage.setItem("lastPing", lastPingDate.toString());
    } else {
      const currentTime = new Date();
      const elapsedMilliseconds = currentTime.getTime() - lastPingDate.getTime();
      remainingTime = Math.max(0, parseInt(process.env.VUE_APP_PING_INT) - elapsedMilliseconds);
    }
    this.startPinging(remainingTime);
  }
}
</script>
<style>
::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  width: 6.6px;
  border: none;
  background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: v-bind(getScrollBarColor);
}

::-webkit-scrollbar-track {
  background-image: v-bind(getScrollBarBackground);
  background-repeat: repeat-y;
  background-size: contain;
}
@import './assets/css/main.scss';
@font-face {
    font-family: "Inter";
    src: local("Inter"),
    url(./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf) format("truetype");
}
@font-face {
    font-family: "Digital Numbers";
    src: local("Digital Numbers"),
    url(./assets/fonts/Digital_numbers/DigitalNumbers-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Exo 2";
    src: local("Exo 2"),
    url(./assets/fonts/Exo_2/Exo2-VariableFont_wght.ttf) format("truetype");
}
@font-face {
    font-family: "Square Peg";
    src: local("Square Peg"),
    url(./assets/fonts/Square_peg/SquarePeg-Regular.ttf) format("truetype");
}
#app {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
button:focus {
    outline: none !important;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
