<template>
    <div v-if="!loading" class="display-container">
        <div style="height: 100%" v-if="showServices">
            <lbrx-kiosk-first-theme v-model="validating" :popup="false" @setLanguage="setLanguage" @long-press="openQuantity"
                                    @check-service="checkService" @validate="validateServices"
                                    v-if="themeTemplate.theme === 'firstTheme'" :themeTemplate="themeTemplate" :services="services"
            ></lbrx-kiosk-first-theme>
            <lbrx-kiosk-second-theme v-model="validating" :popup="false" @setLanguage="setLanguage" @long-press="openQuantity"
                                     @check-service="checkService" @validate="validateServices"
                                     v-else-if="themeTemplate.theme === 'secondTheme'" :themeTemplate="themeTemplate"
                                     :services="services"></lbrx-kiosk-second-theme>
            <lbrx-kiosk-third-theme v-model="validating" :popup="false" @setLanguage="setLanguage" @long-press="openQuantity"
                                    @check-service="checkService" @validate="validateServices"
                                    v-else-if="themeTemplate.theme === 'thirdTheme'" :themeTemplate="themeTemplate"
                                    :services="services"></lbrx-kiosk-third-theme>
            <lbrx-kiosk-fourth-theme v-model="validating" :popup="false" @setLanguage="setLanguage" @long-press="openQuantity"
                                     @check-service="checkService" @validate="validateServices"
                                     v-else-if="themeTemplate.theme === 'fourthTheme'" :themeTemplate="themeTemplate"
                                     :services="services"></lbrx-kiosk-fourth-theme>
            <lbrx-kiosk-fifth-theme v-model="validating" :popup="false" @long-press="openQuantity"
                                    @check-service="checkService" @validate="validateServices"
                                    v-else-if="themeTemplate.theme === 'fifthTheme' || themeTemplate.theme === 'seventhTheme'" :services="services"
                                    :themeTemplate="themeTemplate"></lbrx-kiosk-fifth-theme>
            <lbrx-kiosk-sixth-theme v-model="validating" :popup="false" @long-press="openQuantity"
                                    @check-service="checkService" @validate="validateServices"
                                    v-else-if="themeTemplate.theme === 'sixthTheme' || themeTemplate.theme === 'eighthTheme'" :services="services"
                                    :themeTemplate="themeTemplate"></lbrx-kiosk-sixth-theme>
        </div>
        <div v-else>
            <lbrx-print-ticket-box @resetAll="resetAll" @cancel_idle="cancelIdle" @start_idle="startIdle" @sending="validating = !validating" @cancel="this.showServices = true"
                                   :services="services" v-model="validating" :directPrint="directPrint" :themeTemplate="themeTemplate"></lbrx-print-ticket-box>
        </div>
    </div>
    <div style="height: 100vh;display: flex" v-else>
        <div class="spinner-border" :style="{'color':themeTemplate.secondaryColor}"
             style="margin: auto;height: 15vmin;width: 15vmin;font-size: 6vmin" role="status">
        </div>
    </div>
    <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable"
                      :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor"
                      :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>
    <lbrx-kiosk-services-popup :themeTemplate="themeTemplate" :service="clickedService"
                               v-if="showServicesPopup && clickedService" @close="validateClickedService"
                               v-model="showServicesPopup"></lbrx-kiosk-services-popup>
    <lbrx-quantity-picker-popup v-if="showQuantityPicker" @submit-quantity="setQuantity"
                                @close="showQuantityPicker = false, setQuantityFor = null" v-model="showQuantityPicker"
                                :max="20" :min="1" :themeTemplate="themeTemplate"></lbrx-quantity-picker-popup>
</template>

<script>
import {kioskService} from "@/_services/kiosk.service";
import LbrxKioskFirstTheme from "@/components/LbrxKioskFirstTheme.vue";
import LbrxKioskSecondTheme from "@/components/LbrxKioskSecondTheme.vue";
import LbrxKioskThirdTheme from "@/components/LbrxKioskThirdTheme.vue";
import LbrxKioskFourthTheme from "@/components/LbrxKioskFourthTheme.vue";
import LbrxKioskFifthTheme from "@/components/LbrxKioskFifthTheme.vue";
import LbrxKioskSixthTheme from "@/components/LbrxKioskSixthTheme.vue";
import LbrxPrintTicketBox from "@/components/LbrxPrintTicketsBox.vue";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
import LbrxKioskServicesPopup from "@/components/LbrxKioskServicesPopup.vue";
import LbrxQuantityPickerPopup from "@/components/LbrxQuantityPickerPopup.vue";

import router from "@/router";
import {locale} from "moment";
export default {
    name: 'KioskWaitingQueue',
    inject: ['echo', 'display'],
    components: {
        LbrxQuantityPickerPopup,
        LbrxKioskServicesPopup,
        LbrxKioskPopup,
        LbrxPrintTicketBox,
        LbrxKioskSixthTheme,
        LbrxKioskFifthTheme,
        LbrxKioskFourthTheme,
        LbrxKioskThirdTheme,
        LbrxKioskSecondTheme,
        LbrxKioskFirstTheme,
    },
    data() {
        return {
            allowIdle: true,
            showQuantityPicker: false,
            setQuantityFor: null,
            validating: false,
            showServices: true,
            clickedService: null,
            showServicesPopup: false,
            themeTemplate:null,
            locale: 'fr',
            config: {},
            loading: true,
            services: [],
            onLine: navigator.onLine,
            showBackOnline: false,
            boxHeight: '40%',
            boxContent: '',
            boxIcon: 'loader',
            boxTextColor: '',
            isClosable: false,
            boxBackGround: "#FFFFFF",
            boxIconColor: '',
            showPopup: false,
            directPrint: false,
            idleTimeout:null,
            numberOfChecked:0,
            nextCountdown: null
        }
    },
    methods: {
        resetAll() {
          this.setLanguage(this.locale);
          this.clickedService = null;
          this.setQuantityFor = null;
          this.showQuantityPicker = false;
          this.validating = true;
          this.services = [];
          this.setServices();
          this.showPopup = false;
          this.numberOfChecked = 0;
          this.directPrint = false;
          this.showServicesPopup = false;
          clearTimeout(this.idleTimeout);
          clearTimeout(this.nextCountdown);
          this.setIdleTimeout();
          this.showServices = true;
          this.validating = false;
          this.allowIdle = true;
        },
        setLanguage(lang) {
            localStorage.setItem('Language', lang)
            this.$i18n.locale = localStorage.getItem('Language')
        },
        setServices() {
            if (window.localStorage.getItem("services")) {
                this.services = JSON.parse(window.localStorage.getItem("services"));
                this.loading = false;
            } else {
                this.loading = true;
                kioskService.loadServices().then(() => {
                    this.setServices();
                })
            }
        },
        showErrorPopup(errorText) {
            this.showPopup = false;
            this.boxIcon = 'error';
            this.isClosable = true;
            this.boxContent = errorText;
            this.boxTextColor = '#EB5757'
            this.boxBackGround = "#FFFFFF";
            this.boxIconColor = '#EB5757';
            this.boxHeight = "40%";
            this.showPopup = true;
        },
        validateClickedService() {
            this.clickedService.checked = false;
            this.clickedService.child.forEach((c) => {
                if (c.checked) {
                    this.clickedService.checked = true;
                }
            })
            this.disableOtherQueues(this.clickedService);
            this.showServicesPopup = false;
        },
        checkService(service) {
          clearTimeout(this.nextCountdown)
          clearTimeout(this.idleTimeout);
            if (service.disabled || this.validating)
                return;
          if(service.child && service.child.length > 0) {
                this.clickedService = service;
                this.showServicesPopup = true;
            } else {
                this.services[this.services.indexOf(service)].checked = !this.services[this.services.indexOf(service)].checked;

                if (!service.checked) {
                  if(this.numberOfChecked > 0)
                      this.numberOfChecked--;
                    service.qte = null;
                } else {
                  this.numberOfChecked++;
                }
                if(this.numberOfChecked > 0) {
                  this.startNextPageCountdown();
                }
                this.disableOtherQueues(service);
            }
        },
      startNextPageCountdown() {
        clearTimeout(this.idleTimeout);
        clearTimeout(this.nextCountdown);
        if(this.themeTemplate.onServiceSelect != null) {
          if(this.themeTemplate.onServiceSelect.status) {
            console.log("setting next page timeout");
            this.nextCountdown = setTimeout(()=> {
              this.validateServices();
              this.setIdleTimeout();
            }, this.themeTemplate.onPrintTicket.timeout);
          }
        }
      },
      openQuantity(service) {
            if (!JSON.parse(JSON.parse(window.localStorage.getItem("kiosk")).config).allow_quantity_services)
                return;
            if (service.child)
                if (service.child.length)
                    return;
            this.setQuantityFor = this.services[this.services.indexOf(service)];
            this.showQuantityPicker = true;
            this.services[this.services.indexOf(service)].checked = true;
        },
        setQuantity(qte) {
            this.setQuantityFor.qte = qte;
        },
        disableOtherQueues(service) {
            if (service.checked) {
                if (!JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket && !+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services) {
                    this.showServices = false;
                    this.directPrint = true;
                }
                this.services.forEach((serv) => {
                    if (service.queue_id !== serv.queue_id || (!+JSON.parse(JSON.parse(window.localStorage.getItem("kiosk")).config).allow_multiple_services && serv.id !== service.id)) {
                        serv.checked = false;
                        serv.disabled = true;
                    }
                })
            } else {
                for (let i = 0; i < this.services.length; i++) {
                    if (this.services[i].checked) {
                        return;
                    }
                }
                this.services.forEach((serv) => {
                    serv.disabled = false;
                });
            }
        },
        validateServices() {
            this.validating = true;
            let serviceChecked = false;
            this.services.forEach((service) => {
                if (service.checked) {
                    serviceChecked = true;
                }
            });
            if (!serviceChecked) {
                //fire error;
                this.validating = false;
                this.showErrorPopup(this.$t('Kiosk.servicesError'));
                return;
            }
            if (!JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket)
                this.directPrint = true;
            this.showServices = false;
            this.validating = false;
        },
        setIdleTimeout() {
          clearTimeout(this.idleTimeout);
            if(this.themeTemplate.IdleTimeout && this.allowIdle) {
                this.idleTimeout = setTimeout(()=>{
                    window.location.reload();
                },this.themeTemplate.IdleTimeout)
            }
        },
      cancelIdle() {
        this.allowIdle = false;
        clearTimeout(this.idleTimeout);
      },
      startIdle() {
        this.allowIdle = true;
        this.setIdleTimeout();
      },
    },
        watch: {
            onLine(v) {
                if (v) {
                    location.reload()
                }
            }
        },
    created() {
        this.themeTemplate = JSON.parse(window.localStorage.getItem('theme'));
        this.setIdleTimeout();
        this.setServices();
      document.addEventListener('click',()=>{
        this.setIdleTimeout();
      });
    },
    beforeUnmount() {
        // this.echo.channel("private-kioskWaitingQueue-" + this.display.id).stopListening('.ticket-call')
        // this.echo.channel("private-kioskWaitingQueue-" + this.display.id).stopListening('.ticket-recall')
        // window.removeEventListener('online', this.updateOnlineStatus)
        // window.removeEventListener('offline', this.updateOnlineStatus)
    }
}
</script>

<style scoped>


.display-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
}


.display-image-container img {
    width: 90vh;
}

.display-image-container video {
    width: 90vh;
    height: 90vh;
}

</style>